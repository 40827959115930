import { Link } from 'gatsby';
import React from 'react';

export function DynamicHeroContent({
    typefaceLogo,
    logoImageAlt,
    heading,
    headingColor = 'milan-text-primary',
    disclaimerColor = 'milan-text-navy',
    disclaimerLinkText,
    disclaimerTextPromise,
    richTextContent,
    buttonLink,
    buttonLabel,
    setShowSignUpModal,
    footerText,
    footerTextColor = 'milan-text-primary',
}) {
    return (
        <div className="col">
            {/* only hide on md */}
            {typefaceLogo && (
                <figure className="pb-4 position-relative z-index-100 text-center d-none d-lg-block">
                    <img
                        id="graphic"
                        src={typefaceLogo}
                        alt={logoImageAlt}
                        className="img-fluid w-auto"
                        style={{ maxHeight: '250px' }}
                    />
                </figure>
            )}
            {heading && <h1 className={`${headingColor} smalltitle text-center`}>{heading}</h1>}
            <div
                className="text-start milan-text-primary milan-rich-text"
                dangerouslySetInnerHTML={{ __html: richTextContent }}
            />
            {/* Button - Book My Consultation */}
            <div className="my-4 text-center">
                <Link className="milan-btn milan-cta-btn" to={buttonLink}>
                    {buttonLabel}
                </Link>
            </div>
            {/* Modal - Stay up to date section */}
            <p className={`${disclaimerColor} mb-lg-0 milan-fp text-start`}>
                <span className="text-decoration-underline pointer" onClick={() => setShowSignUpModal(true)}>
                    <strong>{disclaimerLinkText}</strong>
                </span>{' '}
                <span>{disclaimerTextPromise}</span>
            </p>
            <p className={`${footerTextColor} mb-lg-0 mt-2 milan-fp text-start`}>{footerText}</p>
        </div>
    );
}
